module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-manifest-virtual-bd8385b5f3/0/cache/gatsby-plugin-manifest-npm-2.12.0-476b1e57c4-1d5270ec9a.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nom-nom. Om nom nom nom","short_name":"Nom-nom","lang":"nl","start_url":"/","background_color":"#fffde4","theme_color":"#303080","display":"standalone","icon":"static/icon.svg","icon_options":{"purpose":"maskable any"},"legacy":false,"localize":[{"start_url":"/en/","lang":"en"},{"start_url":"/nl/","lang":"nl"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d98a521c70a7e14fc056f32dccc88a49"},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-offline-virtual-97cfcc3d08/0/cache/gatsby-plugin-offline-npm-3.10.0-719615fcb0-6ed0406517.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-typography-virtual-efb6db9901/0/cache/gatsby-plugin-typography-npm-2.12.0-1324b8c9eb-beabf0a151.zip/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/lib/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
